import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { QuestionComponent } from './main/question/question.component';
import { MainComponent } from './main/main.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IntroductionComponent } from './main/introduction/introduction.component';
import { DetailsComponent } from './main/details/details.component';
import { ContactComponent } from './contact/contact.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { InfoComponent } from './info/info.component';
import { ServicesComponent } from './services/services.component';
import { AngularModalModule } from 'angular-modal-simple';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProblemComponent } from './reservation/problem/problem.component';
import { TimeComponent } from './reservation/time/time.component';
import { DataComponent } from './reservation/data/data.component';
import { DatePipePipe } from './shared/pipe/date-pipe.pipe';
import { DayPipePipe } from './reservation/pipe/day-pipe.pipe';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { AdminComponent } from './admin/admin.component';
import { ReservationDetailsComponent } from './admin/reservation-details/reservation-details.component';
import { SuccessModalComponent } from './shared/success-modal/success-modal.component';
import { ErrorModalComponent } from './shared/error-modal/error-modal.component';
import { LoadingComponent } from './shared/loading/loading.component';
import { CancelComponent } from './cancel/cancel.component';
import { ReservationComponent } from './reservation/reservation.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { HamburgerMenuComponent } from './header/hamburger-menu/hamburger-menu.component';
import { RitaModalComponent } from './about-us/rita-modal/rita-modal.component';
import { FanniModalComponent } from './about-us/fanni-modal/fanni-modal.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { BlogIntroComponent } from './main/blog-intro/blog-intro.component';
import { BlogCardComponent } from './shared/blog-card/blog-card.component';
import { BlogComponent } from './blog/blog.component';
import { BlogPostComponent } from './blog/blog-post/blog-post.component';
import { HttpClientModule } from "@angular/common/http";
import { VideoComponent } from './video/video.component';
import { NewServiceComponent } from './main/new-service/new-service.component';
import { NgOptimizedImage } from "@angular/common";
import {BarbiModalComponent} from "./about-us/barbi-modal/barbi-modal.component";
import {CalendarComponent} from "./reservation/calendar/calendar.component";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    QuestionComponent,
    MainComponent,
    IntroductionComponent,
    DetailsComponent,
    ContactComponent,
    AboutUsComponent,
    InfoComponent,
    ServicesComponent,
    ReservationComponent,
    ProblemComponent,
    TimeComponent,
    DataComponent,
    DatePipePipe,
    DayPipePipe,
    AdminComponent,
    ReservationDetailsComponent,
    SuccessModalComponent,
    ErrorModalComponent,
    LoadingComponent,
    CancelComponent,
    HamburgerMenuComponent,
    RitaModalComponent,
    FanniModalComponent,
    BarbiModalComponent,
    BlogIntroComponent,
    NotFoundComponent,
    BlogCardComponent,
    BlogComponent,
    BlogPostComponent,
    VideoComponent,
    NewServiceComponent,
    CalendarComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    AngularModalModule.forRoot(),
    BrowserAnimationsModule,
    AngularFireFunctionsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    NgxGoogleAnalyticsModule.forRoot('AW-16721761780'),
    NgxGoogleAnalyticsRouterModule.forRoot({include: ['*']}),
    HttpClientModule,
    NgOptimizedImage,
  ],
  providers: [
    {
      provide: REGION,
      useValue: 'europe-west6'
    },
    DatePipePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
